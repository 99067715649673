import React from 'react'
import './experience.css'
import Experience1 from '../components/Experience';

export const Experience = () => {
  return (
    <section className='Experience'>
      <h2 className='tittle2'><span className='yellowText2'>Tech</span>Experience</h2>
      <Experience1/>
    </section>
  )
}


export default Experience;